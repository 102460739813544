import * as React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import { Redirect } from '@reach/router';
import BaseLayout from '../components/layouts/BaseLayout';
import { ModuleRenderer } from '../modules/ModuleRenderer';
import { getPageAttributes } from '../modules/ModuleRenderer/utils';

interface IServiceLandingTemplate {
  data: Queries.ServiceLandingTemplateQuery;
}

const ServiceLandingTemplate: React.FC<
  IServiceLandingTemplate & PageRendererProps
> = ({ data: { page }, ...props }) => {
  if (!page) {
    console.warn('Unable to get data from wordpress pages query');
    return <Redirect to="/404" />;
  }

  const pageAttributes = getPageAttributes(page);

  return (
    <BaseLayout
      alertBannerSettings={(
        page.template !== null
          &&
        'alertBanner' in page.template
          ?
        (page?.template?.alertBanner ?? undefined)
          :
        undefined
      )}
      seo={{
        title: `${page.title}`,
        location: props.location.href,
        ...pageAttributes,
      }}
    >
      <ModuleRenderer
        blocks={page.blocks}
        pageAttributes={{ title: `${page.title}`, ...pageAttributes }}
      />
    </BaseLayout>
  );
};

export default ServiceLandingTemplate;

export const query = graphql`
  query ServiceLandingTemplate($id: String!) {
    page: wpServiceLanding(id: { eq: $id }) {
      ...ServiceLandingPage
    }
  }
`;
